import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";

export const authGuard: CanActivateFn = (route, state) => {
  let authService: AuthService = inject(AuthService);

  if (authService.isTokenExpired()) {
    console.log('token est expiré')
    inject(Router).navigate(['/login']).then(() => 'redirecting to login page');
    return false;
  } else {
    console.log('token non expiré')
    return true;
  }
};
