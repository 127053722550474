<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true" class="border-bottom px-4">
    <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn"
      toggle="visible"
      style="margin-inline-start: -14px;"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

    <c-header-nav class="d-none d-md-flex ms-auto consumption-metric">
      <li class="nav-item">
        <button class="buy-credits-button" (click)="goToPurchasePage()">
          <span class="credits-circle">
            <span class="credits-value">100</span>
            <span class="credits-text">crédits</span>
          </span>
          <span class="buy-credits-text">Buy more credits</span>
        </button>
      </li>

      <li class="nav-item">
        <div class="consumption-container">
          <span class="consumption-label small fw-bold mb-1" data-coreui-i18n="cpuUsage">Consumption</span>
          <span class="consumption-value small text-body-secondary">{{creditConsoMois}}/{{creditConsoLimit}}</span>
        </div>
        <c-progress class="progress-bar-2" thin [value]="progressValue" color="danger"></c-progress>
      </li>
    </c-header-nav>

    <c-header-nav class="mx-0">
      <div class="nav-item py-1">
        <div class="h-100 mx-2 text-body text-opacity-75"></div>
      </div>
      <ng-container *ngTemplateOutlet="themeDropdown"/>
      <div class="nav-item py-1">
        <div class="h-100 mx-2 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown"/>
    </c-header-nav>
  </c-container>
</ng-container>

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0 pe-0" style="display: flex; align-items: center;">
      <c-avatar
        shape="rounded-1"
        [size]="'custom'"
        color="primary"
        style="margin-right: 10px;"
        textColor="white"
      >{{organisationInitials}}</c-avatar>
      <div style="display: flex; flex-direction: column;">
        <span class="text-high-emphasis">
          {{currentOrganisation}}
        </span>
        <span class="small text-body-secondary text-nowrap">
          {{currentChannel?.name}}
        </span>
      </div>
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top">
          {{'header-menu.channel.title' | translate}}
        </h6>
      </li>
      <li>
        <a cDropdownItem (click)="openNewChannelModal($event)">
          <svg cIcon [cIcon]="icon.cilLibraryAdd" class="me-2" name="cilLibraryadd"></svg>
          {{'header-menu.channel.links.create' | translate}}
        </a>
      </li>
      <li>
        <a cDropdownItem (click)="openSwitchChannelModal($event)">
          <svg cIcon [cIcon]="icon.cilTransfer" class="me-2"></svg>
          {{'header-menu.channel.links.switch' | translate}}
        </a>
      </li>

      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2">
          Account
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="profile">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="organisation/details">
          <svg cIcon [cIcon]="icon.cilBriefcase" class="me-2"></svg>
          Organisation
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="organisation/members">
          <svg cIcon class="me-2" name="cilPeople"></svg>
          Members
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="organisation/billing">
          <svg cIcon class="me-2" name="cilNotes"></svg>
          Billing
        </a>
      </li>
      <li>
        <hr cDropdownDivider/>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li>
      <li>
        <a color="black" class="color" cButton cDropdownItem (click)="logout()">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      <svg cIcon [cIcon]="icon.cilGlobeAlt" size="xl" title="Globe Icon"></svg>
    </button>
    <ul variant="btn-group" cDropdownMenu>
      <li *ngFor="let language of languages">
        <button cButton cDropdownItem class="Indicator" (click)="changeLanguage(language)"
                style="color: #243f5b !important;">
          <svg cIcon [name]="language==='en'? 'cifGb' : 'cif'+(language|titlecase) " size="lg" title="List Icon"></svg>
          {{'commons.languages.' + language | translate}}
        </button>
      </li>
    </ul>
  </c-dropdown>
</ng-template>
