import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {IconDirective} from "@coreui/icons-angular";
import {
  ButtonDirective,
  ColComponent,
  ContainerComponent,
  FormControlDirective,
  FormLabelDirective,
  FormSelectDirective,
  ModalComponent,
  PlaceholderDirective,
  RowComponent
} from "@coreui/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {ChannelsService} from "../../services/channel.service";
import {NgForOf, NgIf} from "@angular/common";
import {Subscription} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ChannelModel} from "../../../../core/auth/models/channel.model";

@Component({
  selector: 'app-create-channel',
  standalone: true,
  imports: [
    IconDirective,
    ModalComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormSelectDirective,
    PlaceholderDirective,
    RowComponent,
    ColComponent,
    ContainerComponent,
    NgSelectModule,
    ButtonDirective,
    FormLabelDirective,
    FormControlDirective,
    NgForOf,
    NgIf
  ],
  templateUrl: './create-channel.component.html',
  styleUrls: ['./create-channel.component.scss'],
  providers: [BsModalService]
})
export class CreateChannelComponent implements OnDestroy, OnInit {
  channelToUpdate!: ChannelModel;
  createChannelForm: FormGroup;
  flows: string[] = ['OTP', 'MARKETING', 'TRANSACTIONAL'];
  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private channelsService: ChannelsService, private translateService: TranslateService,
              private modalRef: BsModalRef, private toastrService: ToastrService) {
    this.createChannelForm = this.fb.group({
      name: [null, [Validators.required]],
      flow: [null, [Validators.required]],
      creditConsoLimit: [null, [Validators.required, Validators.min(2)]]
    });

  }

  ngOnInit(): void {
    if (this.channelToUpdate) {
      this.createChannelForm.patchValue({
        name: this.channelToUpdate.name,
        flow: this.channelToUpdate.flow,
        creditConsoLimit: this.channelToUpdate.creditConsoLimit
      })
      this.createChannelForm.removeControl('flow');
      if (this.channelToUpdate.creditConsoLimit == -1 || this.channelToUpdate.creditConsoLimit == 0) {
        this.createChannelForm.removeControl('creditConsoLimit');
      }
      this.createChannelForm.updateValueAndValidity();
    }

  }

  submitChannel() {
    if (this.channelToUpdate) {
      let payload = {...this.channelToUpdate};
      payload.name = this.createChannelForm.value.name;
      payload.creditConsoLimit = this.createChannelForm.value.creditConsoLimit;
      this.subscriptions.push(this.channelsService.updateChannel(payload).subscribe({
        next: (res) => {
          console.info('Channel updated successfully. API response is:', res);
          let success_message = this.translateService.instant('channels.edit-page.toastr.success');
          success_message = success_message.replace(':channelName', payload.name)
          this.toastrService.success(success_message);
          this.closeModal();
        },
        error: (err) => {
          console.error('An error occurred during update of your channel. More info about the error here:', err);
          this.toastrService.error(this.translateService.instant('channels.edit-page.toastr.error'))
          this.closeModal();
        }
      }));
    } else {
      const payload = {
        ...this.createChannelForm.value,
        type: 'SMS'
      };
      this.subscriptions.push(this.channelsService.createChannel(payload).subscribe({
        next: (res) => {
          console.info('Channel created successfully. API response is:', res);
          let success_message = this.translateService.instant('channels.create-page.toastr.success');
          success_message = success_message.replace(':channelName', payload.name)
          this.toastrService.success(success_message);
          this.closeModal();
        },
        error: (err) => {
          console.error('An error occurred during creation of your channel. More info about the error here:', err);
          this.toastrService.error(this.translateService.instant('channels.create-page.toastr.error'))
          this.closeModal();
        }
      }));
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }


}
