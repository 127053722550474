<!--Modal title-->
<div class="modal-header justify-content-center align-items-center">
  <h4 class="modal-title">
    {{channelToUpdate? ('channels.edit-page.title.main' | translate): ('channels.create-page.title.main' | translate)}}
  </h4>
</div>

<form [formGroup]="createChannelForm" (ngSubmit)="submitChannel()">

  <!--Modal body-->
  <div class="modal-body mt-4">
    <!--Name input-->
    <c-row class="mb-3">
      <c-col [md]="12">
        <label cLabel class="required">{{'channels.create-page.form.name-input.label' | translate}}</label>
        <input cFormControl formControlName="name" type="text"
               placeholder="{{'channels.create-page.form.name-input.placeholder' | translate}}">
      </c-col>
    </c-row>
    <!--Flow input-->
    <c-row class="mb-3" *ngIf="!channelToUpdate">
      <c-col [md]="12">
        <label cLabel class="required">{{'channels.create-page.form.flow-type-input.label' | translate}}</label>
        <select cSelect formControlName="flow">
          <option *ngFor="let value of flows" [value]="value"
                  selected>{{('channels.create-page.form.flow-type-input.values.' + value) | translate}}</option>
        </select>
      </c-col>
    </c-row>
    <!--Daily limit input-->
    <c-row *ngIf="!(channelToUpdate.creditConsoLimit ==0 ||channelToUpdate.creditConsoLimit==-1)">
      <c-col [md]="12">
        <label cLabel class="required">{{'channels.create-page.form.daily-limit-input.label' | translate}}</label>
        <input formControlName="creditConsoLimit" type="number" min="2"
               placeholder="{{'channels.create-page.form.daily-limit-input.placeholder' | translate}}"
               cFormControl>
      </c-col>
    </c-row>
  </div>

  <!--Modal footer-->
  <div class="mod-footer">
    <c-row>
      <c-col class="d-flex justify-content-end">
        <button cButton class="ms-1" variant="ghost" color="secondary" (click)="closeModal()">
          {{'commons.cta.cancel'|translate}}
        </button>

        <button cButton type="submit" class="ms-1" variant="outline" color="success"
                [disabled]="!createChannelForm.valid">
          {{channelToUpdate ? ('commons.cta.update'|translate) : ('commons.cta.create'|translate)}}
        </button>
      </c-col>
    </c-row>
  </div>
</form>
