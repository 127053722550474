import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AvatarComponent,
  BadgeComponent,
  BreadcrumbRouterComponent,
  ButtonDirective,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  NavItemComponent,
  NavLinkDirective,
  ProgressBarDirective,
  ProgressComponent,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective
} from '@coreui/angular';
import {NgForOf, NgStyle, NgTemplateOutlet, TitleCasePipe} from '@angular/common';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {IconDirective} from '@coreui/icons-angular';
import {AuthService} from "../../../auth/services/auth.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {cilBriefcase, cilGlobeAlt, cilLibraryAdd, cilTransfer} from "@coreui/icons";
import {LanguageEnum} from "../../../internationalisation/models/language.enum";
import {UtilsService} from "../../../../shared/services/utils.service";
import {ChannelsService} from "../../../../features/channel-modals/services/channel.service";
import {Subscription} from "rxjs";
import {BsModalService} from "ngx-bootstrap/modal";
import {
  CreateChannelComponent
} from "../../../../features/channel-modals/modals/create-channel/create-channel.component";
import {ChannelModel} from "../../../auth/models/channel.model";
import {
  SwitchChannelComponent
} from "../../../../features/channel-modals/modals/switch-channel/switch-channel.component";

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrl: './default-header.component.scss',
  standalone: true,
  providers: [BsModalService],
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, IconDirective, HeaderNavComponent, NavItemComponent, NavLinkDirective, RouterLink, RouterLinkActive, NgTemplateOutlet, BreadcrumbRouterComponent, ThemeDirective, DropdownComponent, DropdownToggleDirective, TextColorDirective, AvatarComponent, DropdownMenuDirective, DropdownHeaderDirective, DropdownItemDirective, BadgeComponent, DropdownDividerDirective, ProgressBarDirective, ProgressComponent, NgStyle, ButtonDirective, NgForOf, TitleCasePipe, TranslateModule]
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit, OnDestroy {
  icon = {cilGlobeAlt, cilBriefcase, cilTransfer, cilLibraryAdd};
  languages = [LanguageEnum.FR, LanguageEnum.EN, LanguageEnum.ES, LanguageEnum.IT];
  selectedLanguage!: string;
  organisationInitials: string = '';
  currentOrganisation!: string;
  currentChannel!: ChannelModel;
  creditConsoMois: number = 0;
  creditConsoLimit: number = 0;
  progressValue: number = 0;
  private subscriptions: Subscription[] = [];

  @Input() sidebarId: string = 'sidebar1';
  /*  @Output() openSwitchCanal = new EventEmitter<void>();
    @Output() openCreateChannel = new EventEmitter<void>();*/

  /*  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    readonly #colorModeService = inject(ColorModeService);
    readonly colorMode = this.#colorModeService.colorMode;
    readonly #destroyRef: DestroyRef = inject(DestroyRef);*/

  /*  readonly colorModes = [
      { name: 'light', text: 'Light', icon: 'cilSun' },
      { name: 'dark', text: 'Dark', icon: 'cilMoon' },
      { name: 'auto', text: 'Auto', icon: 'cilContrast' }
    ];*/

  /*  readonly icons = computed(() => {
      const currentMode = this.colorMode();
      return this.colorModes.find(mode => mode.name === currentMode)?.icon ?? 'cilSun';
    });*/

  constructor(private router: Router, private authService: AuthService, private translateService: TranslateService,
              private channelsService: ChannelsService, private modalService: BsModalService) {
    super();
    this.selectedLanguage = this.translateService.currentLang;
    this.subscriptions.push(this.authService.getOrganisations().subscribe((res) => {
      this.currentOrganisation = res.name;
      this.organisationInitials = this.getInitials(this.currentOrganisation);
    }));
    this.subscriptions.push(this.authService.getChannels().subscribe((res) => {
      console.log('your res channels are:', res);
      this.currentChannel = res.filter(channel => channel.channelId + '' == localStorage.getItem(AuthService.CHANNEL_ID) || channel.channelUuid == localStorage.getItem(AuthService.CHANNEL_UUID))[0];
    }))


    /*    this.#colorModeService.localStorageItemName.set('coreui-free-angular-admin-template-theme-default');
        this.#colorModeService.eventName.set('ColorSchemeChange');

        this.#activatedRoute.queryParams
          .pipe(
            delay(1),
            map(params => <string>params['theme']?.match(/^[A-Za-z0-9\s]+/)?.[0]),
            filter(theme => ['dark', 'light', 'auto'].includes(theme)),
            tap(theme => {
              this.colorMode.set(theme);
            }),
            takeUntilDestroyed(this.#destroyRef)
          )
          .subscribe();*/
  }

  ngOnInit(): void {
    this.fetchSelectedChannel();
  }

  fetchSelectedChannel(): void {
    const selectedChannelId = localStorage.getItem(AuthService.CHANNEL_ID);
    const selectedChannelUuid = localStorage.getItem(AuthService.CHANNEL_UUID);
    if (selectedChannelId || selectedChannelUuid) {
      this.channelsService.getChannels().subscribe((channels) => {
        const selectedChannel = channels.find(channel =>
          // @ts-ignore
          channel.channelId === +selectedChannelId || channel.channelUuid === selectedChannelUuid
        );
        if (selectedChannel) {
          this.creditConsoMois = selectedChannel.creditConsoMois;
          this.creditConsoLimit = selectedChannel.creditConsoLimit;
          this.progressValue = (this.creditConsoMois / this.creditConsoLimit) * 100;
        }
      });
    }
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
    this.selectedLanguage = language;
  }

  logout() {
    localStorage.removeItem(AuthService.TOKEN);
    this.router.navigate(['/login']).then(() => console.log('routing to login page'));
  }

  getInitials(input: string) {
    return UtilsService.getInitials(input);
  }

  openNewChannelModal(event: MouseEvent) {
    event.preventDefault();
    this.modalService.show(CreateChannelComponent);
  }

  openSwitchChannelModal(event: MouseEvent) {
    event.preventDefault();
    this.modalService.show(SwitchChannelComponent);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  goToPurchasePage(){
    this.router.navigate(['/billing/purchase']).then(() => console.log('Routing to purchase page'));
  }
}
