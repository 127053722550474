import {INavData} from '@coreui/angular';

export const navItems: INavData[] = [
  /*    {
        title: true,
        name: 'NAVIGATION'
      },*/
  {
    name: 'Dashboard',
    url: '/dashboards',
    iconComponent: {name: 'cil-speedometer'}
  },
  {
    name: 'Analytics',
    url: '/statistics',
    iconComponent: {name: 'cil-graph'}
  },
  {
    name: 'Messaging',
    url: '/sms',
    iconComponent: {name: 'cil-comment-square'},
    children: [
      {
        name: 'SMS List',
        url: '/sms/sms-list',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Send',
        url: '/sms/send',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Scheduled',
        url: '/sms/scheduled',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Response',
        url: '/sms/response',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'WhatsApp Business',
        url: '/sms/whatsapp-business',
        icon: 'nav-icon-bullet'
      }
    ]
  },
  {
    name: 'Address Book',
    url: '/contacts',
    iconComponent: {name: 'cil-address-book'},
    children: [
      {
        name: 'Contacts',
        url: '/contacts/list',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Groups',
        url: '/contacts/groups',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Upload contacts',
        url: '/contacts/import',
        icon: 'nav-icon-bullet'
      }
      ,
      {
        name: 'STOP List',
        url: '/contacts/blacklist',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Invalid numbers',
        url: '/contacts/invalid',
        icon: 'nav-icon-bullet'
      }
    ]
  },
  {
    name: 'My templates',
    url: '/models',
    iconComponent: {name: 'cil-short-text'}
  },
  {
    name: 'Billing',
    url: '/billing',
    iconComponent: {name: 'cil-wallet'},
    children: [
      {
        name: 'Purchase',
        url: '/billing/purchase',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Transactions',
        url: '/billing/transfers',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Invoices',
        url: '/billing/invoices',
        icon: 'nav-icon-bullet'
      }
    ]
  },
  {
    name: 'Settings',
    url: '/settings',
    iconComponent: {name: 'cil-settings'},
    children: [
      {
        name: 'Sub-organisations',
        url: '/settings/sub-organisations',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Sender IDs',
        url: '/settings/sender-ids',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Alert',
        url: '/settings/alert',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'API Keys',
        url: '/settings/api-keys',
        icon: 'nav-icon-bullet'
      }
      ,
      {
        name: 'Webhooks',
        url: '/settings/webhooks',
        icon: 'nav-icon-bullet'
      }
    ]
  },
  {
    name: 'Services',
    url: '/services',
    iconComponent: {name: 'cil-healing'},
    children: [
      {
        name: 'Mail2SMS (SMTP)',
        url: '/services/mailtosms',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Google Calendar',
        url: '/services/calendar',
        icon: 'nav-icon-bullet'
      }
    ]
  },
  {
    name: 'HELP',
    title: true
  },
  {
    name: 'Support',
    url: '/support',
    linkProps: {fragment: 'headings'},
    iconComponent: {name: 'cil-handshake'}
  },
  {
    name: 'Etat de service',
    title: true
  },
  {
    name: 'All systems Operational',
    url: '/theme/typography',
    linkProps: {fragment: 'headings'},
    iconComponent: {name: 'cil-circle'}
  }

];
