import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Injector} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions
} from '@angular/router';

import {DropdownModule, SidebarModule} from '@coreui/angular';
import {IconSetService} from '@coreui/icons-angular';
import {routes} from './app.routes';
import {HttpClient, HttpClientModule, provideHttpClient, withInterceptors} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {tokenInterceptor} from "./core/auth/interceptors/token.interceptor";
import {AuthService} from "./core/auth/services/auth.service";
import {provideToastr} from "ngx-toastr";
import {channelInterceptor} from "./core/auth/interceptors/channel.interceptor";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function checkAccessToken(httpClient: HttpClient, injector: Injector) {
  return () => {
    //retrieve token
    let token = localStorage.getItem(AuthService.TOKEN);
    let authService = injector.get(AuthService);

    if (token) {
      console.log('token object found ...');
      if (authService.isTokenExpired()) {
        console.log('the token is expired');
        authService.logout();
      } else {
        console.log('the token is not expired, will populate user ...');
        //will populate currentUser in case it's not filled
        authService.populateCurrentUser().subscribe({
            next: () => {
              console.info('all good');
            },
            error: () => {
              console.error("An error happened when populating current user")
              authService.logout();
            }
          }
        )
      }

    }

  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions()
    ),
    importProvidersFrom(SidebarModule, DropdownModule),
    IconSetService,
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        "loader": {
          "provide": TranslateLoader,
          "useFactory": createTranslateLoader,
          "deps": [HttpClient],
        },
      })
    ),
    provideHttpClient(withInterceptors([tokenInterceptor, channelInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: checkAccessToken,
      multi: true,
      deps: [HttpClient, Injector]
    },
    provideAnimations(),
    provideToastr({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    })
  ]
};
