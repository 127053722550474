<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
      <svg [class]="{icon: false}" cIcon class="sidebar-brand-full" height="32" name="logo" title="CoreUI Logo"></svg>
      <svg cIcon class="sidebar-brand-narrow" height="32" name="signet" title="CoreUI Logo"></svg>
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow"
                pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" compact/>
  </ng-scrollbar>

  <li class="nav-item px-3 pb-2 d-narrow-none bg-success metric">
    <div class="text-uppercase small fw-bold mb-1" data-coreui-i18n="cpuUsage">Consumption</div>
    <c-progress thin [value]="25" color="danger"/>

    <div class="small text-body-secondary">number of messages 75/100</div>
  </li>

  @if (!sidebar1.narrow) {
  <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex" toggle="unfoldable" style="cursor: pointer;">
    <button cSidebarToggler></button>
  </c-sidebar-footer>
  }
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header [cShadowOnScroll]="'sm'"
                      class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
                      position="sticky"
  />
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container fluid class="h-auto px-4">
      <router-outlet/>
    </c-container>
  </div>
  <!--app footer-->
  <!--  <app-default-footer />-->
</div>

<!-- Feedback Button -->
<button cButton cOffcanvasToggle="OffcanvasEnd" class="feedback-button">
  <span class="vertical-text">Feedback</span>
  <svg [cIcon]="icons.cilSpeech"></svg>
</button>

<!-- Offcanvas End -->
<c-offcanvas
  placement="end"
  id="OffcanvasEnd"
>
  <c-offcanvas-header>
    <c-row class="w-100 align-items-end justify-content-end">
      <c-col xs="1">
        <button
          cButtonClose
          class="text-reset"
          cOffcanvasToggle="OffcanvasEnd"
          aria-label="Close"
        ></button>
      </c-col>
    </c-row>

  </c-offcanvas-header>
  <c-offcanvas-body>
    <p>
      Content for the offcanvas goes here. You can place just about any CoreUI component or custom elements here.
    </p>
  </c-offcanvas-body>
</c-offcanvas>
