import {Routes} from '@angular/router';
import {DefaultLayoutComponent} from './core/layout';
import {authGuard} from "./core/auth/guards/auth.guard";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    canActivate: [authGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboards/routes').then((m) => m.routes)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./features/activity/routes').then((m) => m.routes)
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./features/statistics/routes').then((m) => m.routes),
      },
      {
        path: 'sms',
        loadChildren: () =>
          import('./features/messaging/routes').then((m) => m.routes),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./features/settings/routes').then((m) => m.routes),
      },
      {
        path: 'models',
        loadChildren: () =>
          import('./features/templates/routes').then((m) => m.routes),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./features/address-book/routes').then((m) => m.routes)
      },
      {
        path: 'services',
        loadChildren: () => import('./features/services/routes').then((m) => m.routes)
      },
      {
        path: 'organisation',
        loadChildren: () => import('./features/organisation/routes').then((m) => m.routes)
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/profile/routes').then((m) => m.routes)
      },
      {
        path: 'billing',
        loadChildren: () => import('./features/billing/routes').then((m) => m.routes)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./features/old/dashboard/routes').then((m) => m.routes)
      },
      {
        path: 'support',
        loadChildren: () => import('./features/support/routes').then((m) => m.routes)
      },
      {
        path: 'theme',
        loadChildren: () => import('./features/old/theme/routes').then((m) => m.routes)
      },
      {
        path: 'base',
        loadChildren: () => import('./features/old/base/routes').then((m) => m.routes)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./features/old/buttons/routes').then((m) => m.routes)
      },
      {
        path: 'forms',
        loadChildren: () => import('./features/old/forms/routes').then((m) => m.routes)
      },
      {
        path: 'icons',
        loadChildren: () => import('./features/old/icons/routes').then((m) => m.routes)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./features/old/notifications/routes').then((m) => m.routes)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./features/old/widgets/routes').then((m) => m.routes)
      },
      {
        path: 'charts',
        loadChildren: () => import('./features/old/charts/routes').then((m) => m.routes)
      },
      {
        path: 'pages',
        loadChildren: () => import('./features/old/pages/routes').then((m) => m.routes)
      }
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./features/old/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./features/old/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./core/auth/pages/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    loadComponent: () => import('./core/auth/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./core/auth/pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
    data: {
      title: 'RestPassword Page'
    }
  },
  {path: '**', redirectTo: 'dashboard'}
];
