<!--Modal title-->
<div class="modal-header justify-content-center align-items-center">
  <h4 class="modal-title">
    {{'channels.switch-page.title.main' | translate}}
  </h4>
</div>

<!--Modal body-->
<div class="modal-body mt-4">
  <c-row>
    <c-col class="channel" [md]="6" *ngFor="let channel of channels">
      <c-card class="mb-3" [cBorder]="{top: {color: (isChannelActive(channel)?'primary': 'secondary'), width: 3}}">
        <c-card-body class="active">
          <c-row>
            <!--Channel name and type-->
            <c-col [md]="10" class="first-col" (click)="selectChannel(channel)">
              <h4 cTooltipPlacement="top"
                  [cTooltip]="channel.name">{{(channel.name | slice: 0: 15) + (isNameLong(channel.name) ? '...' : '')}}</h4>
              <span class="badge bg-secondary">{{channel.flow | titlecase}}</span>
            </c-col>

            <!--Edit button-->
            <c-col [md]="2" class="d-flex justify-content-end second-col">
              <button class="edit-btn" cButton color="success" variant="ghost" size="sm"
                      (click)="editChannel(channel)">
                <svg [cIcon]="icons.cilPen" size="sm"></svg>
              </button>
            </c-col>
          </c-row>

          <!--Channel daily consumption progress-->
          <c-row class="mt-2" (click)="selectChannel(channel)">
            <c-col [md]="12">
              <c-progress thin [value]="calculateProgressValue(channel)" color="danger"/>
              <div class="small text-body-secondary">
                {{'channels.switch-page.daily-consumption'|translate}}
                {{channel.creditConsoMois}}
                / {{(channel.creditConsoLimit == 0 || channel.creditConsoLimit == -1) ? '∞' : channel.creditConsoLimit}}
              </div>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
</div>

<!--Modal footer-->
<div class="mod-footer">
  <c-row>
    <c-col class="d-flex justify-content-end">
      <button cButton class="ms-1" variant="ghost" color="secondary" (click)="closeModal()">
        {{'commons.cta.cancel'|translate}}
      </button>
    </c-col>
  </c-row>
</div>
