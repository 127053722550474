import {HttpInterceptorFn} from '@angular/common/http';
import {AuthService} from "../services/auth.service";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  const token = localStorage.getItem(AuthService.TOKEN);
  if (token) {
    // Clone the request and add the authorization header
    const authReq = req.clone({
      setHeaders: {
        'X-Auth-Token': `${JSON.parse(token).token}`
      }
    });
    // Pass the cloned request with the updated header to the next handler
    return next(authReq);
  } else {
    return next(req);
  }

};
