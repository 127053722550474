import {HttpInterceptorFn} from '@angular/common/http';
import {CHANNEL_ID} from "../../../../environments/environment.common";
import {AuthService} from "../services/auth.service";

export const channelInterceptor: HttpInterceptorFn = (req, next) => {
  let channelId = localStorage.getItem(AuthService.CHANNEL_ID) as string;
  // Clone the request and replace the channelId with 1
  const channelReq = req.clone({
    url: req.url.replace(CHANNEL_ID, channelId)
  });
  // Pass the cloned request with the updated channelId value
  return next(channelReq);
};
