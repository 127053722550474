import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {Title} from '@angular/platform-browser';

import {IconSetService} from '@coreui/icons-angular';
import {iconSubset} from './core/icons/icon-subset';
import {TranslateService} from "@ngx-translate/core";
import {InternationalizationService} from "./core/internationalisation/services/internationalization.service";

@Component({
  selector: 'app-root',
  template: '<router-outlet />',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  title = 'Smsmode';

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private translateService: TranslateService,
    private i18service: InternationalizationService
  ) {
    //handle language
    let lang = localStorage.getItem(InternationalizationService.LANG)
    if (!lang) {
      console.log('No lang defined');
      this.translateService.setDefaultLang('en');
      const browserLang: any = this.translateService.getBrowserLang();
      i18service.setLanguage(browserLang);
    } else {
      console.log('Lang exists');
      i18service.setLanguage(lang);
    }
    this.titleService.setTitle(this.title);
    // iconSet singleton
    this.iconSetService.icons = {...iconSubset};
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
