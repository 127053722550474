import {Component, OnDestroy} from '@angular/core';
import {IconDirective} from "@coreui/icons-angular";
import {
  BorderDirective,
  ButtonDirective,
  CardBodyComponent,
  CardComponent,
  CardTextDirective,
  CardTitleDirective,
  ColComponent,
  ContainerComponent,
  ModalComponent,
  ModalToggleDirective,
  ProgressComponent,
  RowComponent,
  TooltipDirective
} from "@coreui/angular";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CommonModule, NgForOf, NgIf, NgStyle, TitleCasePipe} from "@angular/common";
import {ChannelsService} from '../../services/channel.service';
import {AuthService} from "../../../../core/auth/services/auth.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Subscription} from "rxjs";
import {ChannelModel} from "../../../../core/auth/models/channel.model";
import {ToastrService} from "ngx-toastr";
import {NgApexchartsModule} from "ng-apexcharts";
import {cilPen} from "@coreui/icons";
import {PopoverModule} from "ngx-bootstrap/popover";
import {CreateChannelComponent} from "../create-channel/create-channel.component";

@Component({
  selector: 'app-switch-canal',
  standalone: true,
  imports: [
    IconDirective,
    CommonModule,
    ModalComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    ModalToggleDirective,
    ContainerComponent,
    RowComponent,
    ColComponent,
    CardBodyComponent,
    CardComponent,
    CardTextDirective,
    CardTitleDirective,
    NgForOf,
    NgIf,
    NgStyle,
    ButtonDirective,
    TitleCasePipe,
    NgApexchartsModule,
    ProgressComponent,
    PopoverModule,
    TooltipDirective,
    BorderDirective
  ],
  templateUrl: './switch-channel.component.html',
  styleUrls: ['./switch-channel.component.scss'],
  providers: [BsModalService]
})
export class SwitchChannelComponent implements OnDestroy {

  searchForm: FormGroup;
  channels: ChannelModel[] = [];
  icons = {cilPen};
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private channelsService: ChannelsService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private modalService: BsModalService
  ) {
    this.fetchChannels();
    this.searchForm = this.fb.group({
      search: [null]
    });
  }

  fetchChannels() {
    this.subscriptions.push(this.channelsService.getChannels().subscribe({
      next: (res) => {
        console.info('Channels retrieved successfully. Your API response is:', res);
        this.channels = res;
      },
      error: (err) => {
        console.error('An error occurred when fetching channels. More info about the error is:', err);
        this.toastrService.error(this.translateService.instant('channels.switch-page.toastr.error'));
        this.closeModal();
      }
    }))
  }

  closeModal() {
    this.modalRef.hide();
  }

  calculateProgressValue(channel: ChannelModel) {
    let limit = 0;
    let value = 0;
    if (channel.creditConsoLimit == -1 || channel.creditConsoLimit == 0) {
      if (channel.creditConsoMois <= 0) {
        limit = 100;
        value = 0;
      } else {
        limit = channel.creditConsoMois * 5;
        value = (channel.creditConsoMois * 100) / limit
      }
    } else {
      limit = channel.creditConsoLimit;
      value = channel.creditConsoMois;
    }
    return value;
  }

  isNameLong(channelName: string) {
    return channelName?.length > 15;
  }

  isChannelActive(channel: ChannelModel) {
    let channelId = localStorage.getItem(AuthService.CHANNEL_ID);
    let channelUuid = localStorage.getItem(AuthService.CHANNEL_UUID);
    if (channelId) {
      return channel.channelId + '' == channelId;
    } else {
      return channel.channelUuid + '' == channelUuid;
    }
  }

  selectChannel(channel: ChannelModel) {
    this.authService.setChannelValuesInLocalStorage(channel);
    this.toastrService.success(this.translateService.instant('channels.switch-page.toastr.success').replace(':channelName', channel.name));
    this.modalRef.hide();
  }

  editChannel(channel: ChannelModel) {
    this.modalRef.hide();
    const initialState = {
      channelToUpdate: channel
    }
    this.modalService.show(CreateChannelComponent, {initialState});
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
