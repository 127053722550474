export const logo = [
  '685 116',
  `<svg width="608" height="134" viewBox="0 0 608 134" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M97.3906 28.1613H70.7759C69.9694 28 69.0016 28 68.1951 28C64.6465 28 61.0979 28.6452 57.7106 30.0969C54.4846 31.5486 51.4198 33.6455 49.1616 36.3876C46.7421 39.1298 45.1291 42.3558 44.3226 46.0657C44.1613 47.0335 44 48.3239 44 49.453C44 52.679 44.6452 55.905 45.9356 58.8085C47.5486 62.3571 49.1616 65.7444 51.2585 68.9704L54.0007 74.6159L69.4855 99.7789L72.8729 105.424L77.7119 99.9402C82.3896 93.972 86.0995 87.3587 88.6803 80.2615H97.068C111.424 80.4228 123.037 68.8091 123.199 54.4533C123.36 40.0976 111.746 28.4839 97.3906 28.3226C97.5519 28.1613 97.5519 28.1613 97.3906 28.1613ZM50.2907 49.9369C50.2907 49.1304 50.2907 48.3239 50.6133 47.6787C51.2585 45.0979 52.5489 42.6784 54.3233 40.7428C56.0976 38.8072 58.3558 37.1941 60.9366 36.2263C63.3561 35.2585 65.7756 34.7746 68.3564 34.7746C70.9372 34.6133 73.3568 35.5811 75.2924 37.1941C77.0667 38.8072 78.1958 41.0654 78.0345 43.4849C78.0345 44.2914 77.8732 45.0979 77.7119 45.9044C77.0667 48.6465 75.7763 51.2273 73.8407 53.3242C71.7438 55.7437 69.3242 57.8407 66.7434 59.615C64.0013 61.5506 60.614 63.6475 56.4202 65.9057C52.3876 59.2924 50.2907 53.9694 50.2907 49.9369ZM97.3906 73.3255H84.6478V73.6481H84.3252C81.9057 80.9067 78.5184 87.6813 74.3246 93.972L60.1301 71.0673C63.84 68.9704 67.5499 66.5509 71.0985 63.9701C74.002 61.8732 76.7441 59.2924 79.0023 56.5503C82.2283 52.679 84.0026 47.6787 84.0026 42.6784C84.0026 39.9363 83.3574 37.3555 82.067 35.0972L81.9057 34.9359H97.5519C108.036 34.9359 116.585 43.4849 116.585 54.1307C116.585 64.7766 108.036 73.3255 97.3906 73.3255Z" fill="#6BB17B"/>
<path d="M141.597 81.2261C139.984 80.5809 138.532 79.4518 137.242 78.1614C136.112 77.0323 135.306 75.5806 134.822 74.1289L140.629 71.5481C141.113 72.8385 141.919 74.1289 142.887 75.0967C144.016 76.0645 145.468 76.5484 146.92 76.5484C147.887 76.5484 148.694 76.3871 149.5 76.0645C150.146 75.7419 150.63 75.258 151.113 74.7741C151.436 74.2902 151.597 73.645 151.597 73.1611C151.597 72.5159 151.436 72.032 150.952 71.5481C150.468 71.0642 149.823 70.7416 149.178 70.5803C148.533 70.2577 147.404 70.0964 146.113 69.7737C144.5 69.2898 142.887 68.8059 141.274 68.1607C139.984 67.6768 138.855 66.709 138.048 65.5799C137.08 64.2895 136.596 62.6765 136.596 61.0635C136.596 59.2892 137.08 57.5149 138.048 55.9019C139.016 54.4502 140.468 53.4824 142.081 52.8372C144.016 51.8694 145.952 51.5468 147.726 51.5468C149.984 51.5468 152.404 52.0307 154.339 53.3211C156.275 54.4502 157.888 56.0632 159.017 57.9988L153.372 61.0635C152.726 59.9344 152.081 59.1279 151.113 58.3214C150.146 57.5149 148.855 57.1923 147.565 57.1923C146.597 57.1923 145.629 57.3536 144.823 57.9988C144.178 58.3214 143.855 59.1279 143.694 59.7731C143.694 60.257 143.855 60.9022 144.339 61.2248C144.823 61.7087 145.307 61.87 145.952 62.1926C146.597 62.3539 147.565 62.6765 148.694 63.1604C150.468 63.6443 152.081 64.2895 153.694 64.9347C154.985 65.5799 156.275 66.5477 157.082 67.6768C158.049 69.1285 158.695 70.9029 158.533 72.6772C158.533 74.6128 158.049 76.5484 156.92 78.1614C155.791 79.6131 154.339 80.7422 152.565 81.5487C150.791 82.3552 148.855 82.6778 146.92 82.6778C145.307 82.3552 143.371 82.0326 141.597 81.2261Z" fill="white"/>
<path d="M165.308 52.192H172.566V55.2567C173.373 54.1276 174.663 53.1598 175.954 52.5146C177.244 51.8694 178.696 51.5468 180.148 51.5468C183.696 51.5468 186.922 53.4824 188.535 56.7084C189.503 55.0954 190.955 53.6437 192.729 52.8372C194.503 52.0307 196.439 51.5468 198.375 51.5468C200.472 51.5468 202.73 52.0307 204.504 53.3211C206.117 54.4502 207.407 56.0632 208.214 57.8375C209.02 59.7731 209.504 61.87 209.343 63.9669V81.8713H201.923V64.9347C202.085 63.3217 201.601 61.5474 200.794 60.0957C199.988 58.8053 198.536 58.1601 197.084 58.3214C195.955 58.3214 194.826 58.644 193.697 59.2892C192.729 59.9344 191.923 60.9022 191.6 61.87C191.116 62.9991 190.793 64.2895 190.793 65.5799V81.8713H183.374V64.7734C183.535 63.1604 183.051 61.3861 182.245 59.9344C181.438 58.644 179.986 57.9988 178.535 58.1601C177.405 58.1601 176.115 58.4827 175.147 59.1279C174.179 59.7731 173.373 60.7409 173.05 61.7087C172.566 62.8378 172.244 64.1282 172.244 65.4186V81.71H164.985L165.308 52.192Z" fill="white"/>
<path d="M220.473 81.226C218.86 80.5807 217.408 79.4516 216.118 78.1612C214.989 77.0321 214.182 75.5804 213.537 73.9674L219.344 71.3866C219.828 72.677 220.634 73.9674 221.602 74.9352C222.731 75.903 224.183 76.3869 225.634 76.3869C226.602 76.3869 227.409 76.2256 228.215 75.903C228.86 75.5804 229.506 75.0965 229.828 74.6126C230.151 74.1287 230.312 73.4835 230.312 72.9996C230.312 72.3544 229.99 71.8705 229.667 71.3866C229.183 70.9027 228.538 70.5801 227.893 70.4188C227.247 70.0962 226.118 69.9349 224.828 69.6123C223.215 69.1284 221.602 68.6445 219.989 67.9993C218.698 67.5154 217.569 66.5476 216.763 65.4185C215.795 64.1281 215.311 62.5151 215.311 60.902C215.311 59.1277 215.795 57.3534 216.763 55.7404C217.731 54.2887 219.182 53.3209 220.795 52.6757C222.57 52.0305 224.344 51.5466 226.28 51.7079C228.538 51.7079 230.957 52.1918 232.893 53.4822C234.829 54.6113 236.442 56.2243 237.571 58.1599L231.925 61.2246C231.28 60.0955 230.635 59.289 229.667 58.4825C228.699 57.676 227.409 57.3534 226.118 57.3534C225.151 57.3534 224.183 57.5147 223.376 58.1599C222.731 58.4825 222.408 59.289 222.247 59.9342C222.247 60.4181 222.408 61.0633 222.892 61.386C223.376 61.8699 223.86 62.0312 224.505 62.3538C225.151 62.5151 226.118 62.8377 227.247 63.3216C229.022 63.8055 230.635 64.4507 232.248 65.0959C233.538 65.7411 234.829 66.7089 235.635 67.838C236.603 69.2897 237.248 71.064 237.087 72.8383C237.087 74.7739 236.603 76.7095 235.474 78.3225C234.345 79.7742 232.893 80.9034 231.119 81.7099C229.344 82.5164 227.409 82.839 225.473 82.839C224.183 82.3551 222.247 82.0325 220.473 81.226Z" fill="white"/>
<path d="M244.184 52.192H251.443V55.2567C252.249 54.1276 253.54 53.1598 254.83 52.5146C256.12 51.8694 257.572 51.5468 259.024 51.5468C262.572 51.5468 265.798 53.4824 267.411 56.7084C268.379 55.0954 269.831 53.6437 271.605 52.8372C273.38 52.0307 275.315 51.5468 277.251 51.5468C279.348 51.5468 281.606 52.0307 283.38 53.3211C284.993 54.4502 286.284 56.0632 287.09 57.8375C287.897 59.7731 288.38 61.87 288.219 63.9669V81.8713H280.799V64.9347C280.961 63.3217 280.477 61.5474 279.67 60.0957C278.864 58.8053 277.412 58.1601 275.96 58.3214C274.831 58.3214 273.702 58.644 272.573 59.2892C271.605 59.9344 270.799 60.9022 270.476 61.87C269.992 62.9991 269.67 64.2895 269.67 65.5799V81.8713H262.25V64.7734C262.411 63.1604 261.927 61.3861 261.121 59.9344C260.314 58.644 258.862 57.9988 257.411 58.1601C256.282 58.1601 254.991 58.4827 254.023 59.1279C253.056 59.7731 252.249 60.7409 251.926 61.7087C251.443 62.8378 251.12 64.1282 251.12 65.4186V81.71H243.861L244.184 52.192Z" fill="white"/>
<path d="M300.478 80.2582C298.22 78.9678 296.284 77.0322 295.155 74.6127C293.865 72.1931 293.219 69.6123 293.219 66.8702C293.219 64.1281 293.865 61.5473 295.155 59.1278C296.446 56.8696 298.22 54.934 300.478 53.4822C305.478 50.7401 311.446 50.7401 316.286 53.4822C318.544 54.7727 320.479 56.7083 321.608 59.1278C322.899 61.5473 323.544 64.1281 323.544 66.8702C323.544 69.6123 322.899 72.1931 321.608 74.6127C320.318 77.0322 318.544 78.9678 316.286 80.2582C311.446 83.1616 305.317 83.1616 300.478 80.2582ZM312.737 74.9353C313.866 74.1288 314.834 72.9996 315.318 71.5479C315.802 70.0962 316.124 68.4832 316.124 66.8702C316.286 64.612 315.479 62.1925 314.189 60.2569C312.737 58.4826 310.64 57.5148 308.382 57.6761C306.769 57.6761 305.317 57.9987 304.027 58.9665C302.898 59.773 301.93 60.9021 301.446 62.1925C300.962 63.6442 300.639 65.2572 300.639 66.8702C300.639 68.4832 300.962 70.0962 301.446 71.5479C301.93 72.8383 302.898 74.1288 304.027 74.9353C306.769 76.5483 310.156 76.5483 312.737 74.9353Z" fill="white"/>
<path d="M355.643 39.7717V81.71H348.868L348.546 78.3226C347.578 79.613 346.287 80.5808 344.836 81.2261C343.223 81.8713 341.61 82.1939 339.997 82.1939C337.577 82.1939 334.996 81.5487 333.061 80.0969C331.125 78.6452 329.512 76.7096 328.706 74.4514C326.77 69.4511 326.77 63.9669 328.706 58.9665C329.673 56.7083 331.125 54.7727 333.061 53.321C334.996 51.8693 337.416 51.2241 339.997 51.2241C341.61 51.2241 343.223 51.5467 344.674 52.1919C346.126 52.6758 347.417 53.6436 348.384 54.934V39.7717H355.643ZM347.094 73.3223C349.514 69.2898 349.514 64.4508 347.094 60.4182C344.513 57.5148 339.997 57.1922 337.093 59.773C336.771 59.9343 336.609 60.2569 336.287 60.5795C333.867 64.4508 333.867 69.4511 336.287 73.4836C338.868 76.5483 343.384 76.7096 346.449 74.1288C346.61 73.8062 346.933 73.6449 347.094 73.3223Z" fill="white"/>
<path d="M368.386 80.2583C366.127 78.9679 364.353 77.0323 363.385 74.6128C362.256 72.1933 361.611 69.6125 361.772 67.0317C361.772 64.4509 362.256 61.87 363.385 59.4505C364.353 57.031 366.127 55.0954 368.224 53.6437C370.644 52.192 373.386 51.3855 376.289 51.3855C379.193 51.2242 381.935 52.0307 384.193 53.6437C386.129 55.0954 387.742 57.031 388.71 59.2892C389.677 61.5474 390 63.967 390 66.3865C390 67.0317 390 67.5156 390 68.1608C390 68.4834 389.839 68.9673 389.839 69.2899H369.515C370.16 73.645 372.741 75.7419 376.935 75.7419C378.225 75.7419 379.515 75.5806 380.806 74.9354C382.096 74.4515 383.225 73.4837 384.032 72.5159L389.032 76.871C387.58 78.6453 385.645 79.9357 383.548 80.9036C381.451 81.8714 379.193 82.194 376.773 82.194C373.709 82.5166 370.966 81.7101 368.386 80.2583ZM382.903 64.1283C382.741 62.3539 382.096 60.5796 380.806 59.2892C379.515 58.1601 377.902 57.5149 376.289 57.6762C372.579 57.6762 370.321 59.7731 369.515 64.1283H382.903Z" fill="white"/>
</svg>
`
];
