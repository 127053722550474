import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASE_URL, environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ChannelModel} from '../../../core/auth/models/channel.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  private channelsUrl = BASE_URL.concat(environment.channels);

  constructor(private http: HttpClient) {
  }

  getChannels(): Observable<ChannelModel[]> {
    return this.http.get<ChannelModel[]>(this.channelsUrl);
  }

  createChannel(channel: Partial<ChannelModel>): Observable<ChannelModel> {
    return this.http.post<ChannelModel>(this.channelsUrl, channel);
  }

  updateChannel(channel: ChannelModel): Observable<ChannelModel> {
    return this.http.patch<ChannelModel>(BASE_URL.concat(environment.channels_id).replace(':channelId', channel.channelId + ''), channel);
  }
}
